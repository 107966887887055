import React from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { AddNewOrg } from "./AddNewOrg";
import { useAuthState } from "./Auth";
import { Login } from "./Login";
import { OrgView } from "./Org";
import { OrgSettings } from "./OrgSettings";

export function AppRouting() {
  const { user } = useAuthState();
  const { location } = useHistory();

  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/add-new-org" component={AddNewOrg} />
      <Route path="/orgs/:id/settings" component={OrgSettings} />
      <Route path="/">
        {!user ? (
          <Redirect
            to={{ pathname: "/login", state: { referrer: location } }}
          />
        ) : (
          <OrgView />
        )}
      </Route>
    </Switch>
  );
}
