export function makeSlug(title: string): string {
  return (
    title
      .replace(/[-$%^*()?@#!=~\/\\,.+]/g, "")
      .replace(/ /g, "-")
      .replace(/&/g, "and")
      .replace(/'/g, "")
      .replace(/"/g, "")
      .replace(/--/g, "")
      // removes emojis - add new like we added peace: |\u270c
      .replace(
        /([\uE000-\uFEFF]|\u270c|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
        ""
      )
      .toLowerCase()
  );
}
