import React from "react";

export function DSSelect(props: any) {
  const {
    innerref,
    label,
    type,
    name,
    placeholder,
    className,
    value,
    ...propsRest
  } = props;

  let classes = "grid";
  if (className) classes = classes + " " + className;

  return (
    <div className={classes} {...propsRest}>
      {label && (
        <label htmlFor={name} className="font-bold text-sm mb-2">
          {label}
        </label>
      )}

      <select
        id={name}
        name={name}
        ref={innerref}
        value={value}
        className="border-2 border-gray-200 rounded-md h-10 px-4 pr-10 outline-none focus:border-blue transition-all duration-300 ease-in-out"
      >
        {props.children}
      </select>
    </div>
  );
}
