import firebase from "firebase";
const config = {
  apiKey: "AIzaSyAcmReitYueksezenFPTCTaUhFsM0HInQs",
  authDomain: "growthcards.firebaseapp.com",
  databaseURL: "https://growthcards.firebaseio.com",
  projectId: "growthcards",
  storageBucket: "growthcards.appspot.com",
  messagingSenderId: "161695387061",
  appId: "1:161695387061:web:0ffc864c562424b7df1dfc",
  measurementId: "G-X9C9YX02MX",
};
firebase.initializeApp(config);
export default firebase;

export const fs = firebase.firestore();
export const auth = firebase.auth();
