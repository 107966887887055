import React from "react";
import { DSButton } from "./DSButton";

export function DSInput(props: any) {
  const {
    innerref,
    label,
    type,
    name,
    placeholder,
    className,
    ...propsRest
  } = props;

  let classes = "grid";
  if (className) classes = classes + " " + className;

  return (
    <div className={classes} {...propsRest}>
      {label && (
        <label htmlFor={name} className="font-bold text-sm mb-2">
          {label}
        </label>
      )}
      {(type === "text" || !type) && (
        <input
          id={name}
          name={name}
          type={type}
          ref={innerref}
          placeholder={placeholder}
          className="border-2 border-gray-200 rounded-md leading-10 px-4 outline-none focus:border-blue transition-all duration-300 ease-in-out"
        />
      )}

      {type === "submit" && <DSButton onClick={props.onClick}>Save</DSButton>}
    </div>
  );
}
