import React from "react";

export function DSButton(props: {
  children: string | JSX.Element;
  w?: string;
  onClick?: any;
  additionalClasses?: string;
  style?: "hero" | "secondary";
  size?: "small";
}) {
  let classes =
    "text-white text-center w-auto rounded-md focus:outline-none display-block transition-all duration-300 ease-in-out" +
    " " +
    props.additionalClasses;

  switch (props.style) {
    case "hero":
      classes =
        classes +
        " px-8 md:px-12 py-3 md:py-5 text-lg md:text-xl bg-gradient-to-r from-gradientPurple to-gradientBlue";
      break;

    case "secondary":
      classes = classes + " bg-gray-400 hover:bg-gray-500";
      break;

    default:
      classes = classes + " bg-blue hover:bg-darkBlue";
      break;
  }

  switch (props.size) {
    case "small":
      classes = classes + " text-sm font-medium px-2 md:px-4 py-1 md:py-2 ";
      break;

    default:
      classes =
        classes +
        " text-base md:text-lg  font-medium px-4 md:px-8 py-2 md:py-3 ";
      break;
  }

  if (props.additionalClasses)
    classes = classes + " " + props.additionalClasses;

  return (
    <button
      type="button"
      className={classes}
      style={{ width: props.w }}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}
