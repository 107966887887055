import firebase from "firebase";
import React, { useState } from "react";
import { useAuthState } from "./Auth";
import { useSelectedOrg } from "./Org";
import { useOrgs } from "./useOrgs";
import logoBlack from "./assets/logoBlack.svg";
import { Link } from "react-router-dom";

export function Nav() {
  const { fbUser, user } = useAuthState();
  const { orgs, loading } = useOrgs();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const selectedOrg = useSelectedOrg();
  const profileImage: string = fbUser?.photoURL || "";

  return (
    <div className="flex justify-between bg-white  border-b-2 border-gray-200 p-4 items-center">
      <Link to={`/`}>
        <img src={logoBlack} alt="" className="h-7" />
      </Link>
      {fbUser && (
        <div
          className="grid gap-2 grid-flow-col items-center cursor-pointer relative"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <div className="mr-1 font-bold">{selectedOrg?.title}</div>
          <div className="rounded-full overflow-hidden">
            <img
              src={profileImage}
              alt={fbUser.displayName || "User"}
              className="h-11 w-11"
            />
          </div>

          {menuOpen && (
            <div className="absolute top-12 py-2 right-0 w-64 rounded-md bg-white shadow-xl border-2 border-gray-100 z-10">
              {orgs.map((org) => (
                <MenuItem key={org.id} selected={org.id === selectedOrg?.id}>
                  <Link
                    to={"/orgs/" + org.id}
                    style={{
                      display: "block",
                      width: "100%",
                    }}
                  >
                    {org.title}
                  </Link>
                </MenuItem>
              ))}
              <MenuItem>
                <Link
                  to={"/add-new-org"}
                  style={{
                    display: "block",
                    width: "100%",
                  }}
                >
                  ➕ Add New
                </Link>
              </MenuItem>
              {!orgs?.length && <MenuItem>No Organizations</MenuItem>}

              <Separator />
              {selectedOrg && (
                <>
                  <MenuSectionTitle>Organization</MenuSectionTitle>
                  <MenuItem>
                    <Link
                      to={"/orgs/" + selectedOrg.id + "/settings"}
                      style={{ display: "block", width: "100%" }}
                    >
                      Settings
                    </Link>
                  </MenuItem>
                </>
              )}
              <Separator />
              <MenuSectionTitle>Profile</MenuSectionTitle>
              <MenuItem onClick={() => firebase.auth().signOut()}>
                Logout
              </MenuItem>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

function MenuSectionTitle(props: { children: JSX.Element | string }) {
  return (
    <div
      className="font-medium text-md px-5 py-2 cursor-default"
      {...props}
    ></div>
  );
}

function MenuItem(props: {
  children: JSX.Element | string;
  onClick?: React.MouseEventHandler;
  selected?: boolean;
}) {
  return (
    <div
      className="relative px-6 py-3 cursor-pointer hover:bg-gray-100"
      {...props}
    >
      {props.selected && (
        <>
          <div className="absolute left-2 top-5 text-xs rounded-full bg-blue w-2 h-2"></div>
          <div className="font-medium">{props.children}</div>
        </>
      )}
      {!props.selected && <>{props.children}</>}
    </div>
  );
}

function Separator() {
  return <div className="h-1 my-2 bg-gray-100 cursor-default"></div>;
}
