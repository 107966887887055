import React, { useEffect, useState } from "react";
import { fs } from "./firestore";
import { Card } from "./Card";
import { Idea } from "./indeaInterface";
import firebase from "firebase";
import { Box, Skeleton, Spinner } from "@chakra-ui/react";
import { Link, Route } from "react-router-dom";
import { IdeaSingle } from "./IdeaSingle";
import { useSelectedOrg } from "./Org";
import Fuse from "fuse.js";
import { Nav } from "./Nav";
import { DSInput } from "./ds/DSInput";
import { DSSelect } from "./ds/DSSelect";
import { DSButton } from "./ds/DSButton";

interface Filter {
  key: string;
  opStr:
    | "<"
    | "<="
    | "=="
    | "!="
    | ">="
    | ">"
    | "array-contains"
    | "in"
    | "array-contains-any"
    | "not-in";
  value: string | number;
}

interface OrderBy {
  key: string;
  direction: "asc" | "desc" | undefined;
}

export function CardsList() {
  const [ideas, setIdeas] = useState<Idea[]>([]);
  const [ideasDisplay, setIdeasDisplay] = useState<Idea[]>([]);
  const [fuse, setFuse] = useState<Fuse<Idea>>();
  const [search, setSearch] = useState<string>();
  const [activeStages, setActiveStages] = useState<string[]>([]);
  const [orderBy, setOrderBy] = useState<OrderBy>({
    key: "ice",
    direction: "desc",
  });
  const org = useSelectedOrg();
  const [cost, setCost] = useState(0);

  const stages = [
    { label: "Acquisition", value: "acquisition", color: "red-500" },
    { label: "Activation", value: "activation", color: "yellow-400" },
    { label: "Retention", value: "retention", color: "green-400" },
    { label: "Revenue", value: "revenue", color: "purple-400" },
    { label: "Referral", value: "referral", color: "pink-500" },
  ];

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });

  const [loading, setLoading] = useState(false);

  /**
   * Subscribe to ideas collection
   */
  useEffect(() => {
    if (!org?.id) return;

    setLoading(true);

    let ref: firebase.firestore.Query<firebase.firestore.DocumentData> = fs
      .collection("ideas")
      .where("orgId", "==", org?.id);
    if (orderBy) ref = ref.orderBy(orderBy.key, orderBy.direction);
    if (activeStages?.length > 0) ref = ref.where("stage", "in", activeStages);

    const unsubscribe = ref.onSnapshot((snapshot) => {
      const ideas = snapshot.docs.map((doc) => {
        const ideaAny: any = doc.data();
        const idea: Idea = ideaAny;
        return idea;
      });
      setIdeas(ideas);
      setIdeasDisplay(ideas);
      setLoading(false);
      const cost = ideas
        .filter((idea) => idea.status === "approved")
        .reduce((a, b) => a + b.costEstimateHigh, 0);
      setCost(cost);

      const fuse = new Fuse(ideas, {
        distance: 10000,
        includeMatches: true,
        includeScore: true,
        threshold: 0.2,
        keys: [
          {
            name: "title",
            weight: 100,
          },
          {
            name: "description",
            weight: 0.7,
          },
          {
            name: "body",
            weight: 0.7,
          },
          {
            name: "stage",
            weight: 0.3,
          },
        ],
      });
      setFuse(fuse);
    });
    return unsubscribe;
  }, [orderBy, activeStages, org]);

  useEffect(() => {
    if (!search || !fuse) {
      setIdeasDisplay(ideas);
    } else {
      const res = fuse.search(search);
      const filteredIdeas = res.map((item) => item.item);
      setIdeasDisplay(filteredIdeas);
    }
  }, [search, ideas]);

  function toggleStage(stage: string) {
    const newStages = activeStages.includes(stage)
      ? activeStages.filter((s) => s !== stage)
      : [...activeStages, stage];
    setActiveStages(newStages);
  }

  return (
    <>
      <Nav />
      <Route path="/orgs/:orgId/ideas/:id">
        <IdeaSingle referrer={location.pathname} />
      </Route>
      <div className="bg-white flex justify-between items-center">
        <div className="grid grid-cols-2 gap-5 p-4">
          <DSInput
            name="search"
            label="Search"
            placeholder="Search ideas"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearch(e.target.value)
            }
            value={search || ""}
          ></DSInput>
          <DSSelect
            name="sortby"
            label="Sort"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setOrderBy({
                key: e.target.value,
                direction: orderBy?.direction,
              })
            }
            value={orderBy?.key}
          >
            <option value="createdAt">Created At</option>
            <option value="ice">ICE Score</option>
            <option value="impact">Impact</option>
            <option value="confidence">Confidence</option>
            <option value="ease">Ease</option>
          </DSSelect>
        </div>

        <div className="mr-4 flex items-center">
          <div className="mr-4 font-bold">{formatter.format(cost)}</div>
          <DSButton style="secondary" size="small">
            <Link to={"/orgs/" + org?.id + "/ideas/add"}>Add Idea</Link>
          </DSButton>
        </div>
      </div>

      <div className="bg-white flex justify-items-start items-center gap-2 px-4 pb-4">
        {stages.map((stage) => {
          const isActive = activeStages.includes(stage.value);
          const activeClass = isActive
            ? " text-white bg-" + stage.color
            : " text-" + stage.color;
          return (
            <div
              className={
                "cursor-pointer px-3 py-1 rounded-full font-bold text-sm border-2 border-" +
                stage.color +
                " hover:bg-" +
                stage.color +
                " hover:text-white" +
                activeClass
              }
              onClick={() => toggleStage(stage.value)}
            >
              {stage.label}
            </div>
          );
        })}
      </div>

      {loading && (
        <Box
          className="wrapper"
          display="flex"
          justifyContent="center"
          alignItems="center"
          h="100vh"
          maxH="600px"
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="150px"
          >
            <Spinner color="white" />
          </Box>
        </Box>
      )}

      {!loading && (
        <Box
          display="grid"
          gridGap="20px"
          gridTemplateColumns="repeat(auto-fill, 300px)"
          justifyContent="center"
          padding="40px 20px"
        >
          {ideasDisplay &&
            ideasDisplay.map((idea, i) => {
              return (
                <div className="card-wrap" key={idea.id}>
                  <Card idea={idea}></Card>
                </div>
              );
            })}
          {!ideasDisplay && (
            <>
              <Skeleton borderRadius="7px" height="340px" />
              <Skeleton borderRadius="7px" height="340px" />
              <Skeleton borderRadius="7px" height="340px" />
            </>
          )}
        </Box>
      )}
    </>
  );
}
