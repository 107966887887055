import { useEffect, useState } from "react";
import { useAuthState } from "./Auth";
import { Org } from "./OrgInterface";
import firebase from "firebase";
import { fs } from "./firestore";

export function useOrgs() {
  const { user } = useAuthState();
  const [orgs, setOrgs] = useState<Org[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!user?.orgs?.length) return setOrgs([]);
    setLoading(true);
    let ref: firebase.firestore.Query<firebase.firestore.DocumentData> = fs
      .collection("orgs")
      .where("id", "in", user.orgs);

    return ref.onSnapshot((snapshot) => {
      const orgs = snapshot.docs.map((doc) => {
        const orgAny: any = doc.data();
        const org: Org = orgAny;
        return org;
      });
      setOrgs(orgs);
      setLoading(false);
    });
  }, [user]);

  return { orgs, loading };
}
