import { Form, Formik } from "formik";
import "./NewIdeaForm.scss";
import React, { useEffect, useState } from "react";
import { fs } from "./firestore";
import { Idea } from "./indeaInterface";
import { newId } from "./utils/new-id";
import { Box, Button, Flex, Grid, Link, Spinner } from "@chakra-ui/react";
import { FormField } from "./form-components/form-components";
import { useSelectedOrg } from "./Org";
import { useHistory } from "react-router-dom";

export function NewIdeaForm(props: { onClose?: any; idea?: Idea }) {
  const org = useSelectedOrg();
  const stageOptions = [
    "acquisition",
    "activation",
    "retention",
    "revenue",
    "referral",
  ];

  const scores = ["1", "2", "3", "4", "5"];

  const initialValues: Idea = {
    body: "<p>Hello</p>",
    confidence: 1,
    costEstimateLow: 500,
    costEstimateHigh: 1000,
    createdAt: new Date(),
    description: "We'll do things that will make things better",
    ease: 3,
    emoji: "🔥",
    ice: 3,
    impact: 3,
    stage: "acquisition",
    status: "no status",
    title: "Some Great Idea Title",
    updatedAt: new Date(),
  };

  const [idea, setIdea] = useState<Idea>();
  const [isDeleting, setIsDeleting] = useState<boolean>();
  const [isSaving, setIsSaving] = useState<boolean>();
  const history = useHistory();

  useEffect(() => {
    const idea = props.idea ? props.idea : initialValues;
    setIdea({ ...idea });
  }, [props.idea]);

  if (!idea) return <div className="loading">Loading</div>;

  async function addIdea(idea: Idea, setIsSaving: any, onClose: any) {
    if (!org) return alert("No Org can not save");
    setIsSaving(true);
    const creating = !idea?.id;
    if (!idea.id) idea.id = newId();
    if (!idea.orgId) idea.orgId = org.id;
    idea.costEstimateLow = +idea.costEstimateLow;
    idea.costEstimateHigh = +idea.costEstimateHigh;
    await fs.collection("ideas").doc(idea.id).set(idea);
    if (creating) {
      const rootPath = history.location.pathname.split("/ideas/")[0];
      history.push(rootPath + "/ideas/" + idea.id);
      onClose();
    } else {
      onClose();
    }
  }

  async function deleteIdea(idea: Idea, setIsDeleting: any, onClose: any) {
    setIsDeleting(true);
    if (window.confirm("Are you sure?")) {
      await fs.collection("ideas").doc(idea.id).delete();
      const rootPath = history.location.pathname.split("/ideas/")[0];
      history.push(rootPath);
    } else {
      setIsDeleting(false);
    }
  }

  return (
    <Box overflow="scroll">
      <Box position="relative">
        <Box className="form">
          <Formik
            initialValues={idea}
            onSubmit={(idea) => addIdea(idea, setIsSaving, props.onClose)}
            validate={(formIdea: Idea) => {
              formIdea.impact = +formIdea.impact;
              formIdea.confidence = +formIdea.confidence;
              formIdea.ease = +formIdea.ease;
              formIdea.ice =
                (formIdea.impact + formIdea.confidence + formIdea.ease) / 3;
              setIdea({ ...formIdea });
            }}
          >
            <Form>
              <Box mb="20px">
                <FormField inputName="title" type="text"></FormField>
              </Box>

              <Box mb="20px">
                <FormField inputName="emoji" type="text"></FormField>
              </Box>

              <Box mb="20px">
                <FormField inputName="description" type="textarea"></FormField>
              </Box>

              <Box mb="20px">
                <FormField
                  inputName="stage"
                  type="select"
                  options={stageOptions}
                ></FormField>
              </Box>

              <Grid templateColumns="repeat(3,1fr)" gap="15px" mb="20px">
                <FormField
                  inputName="impact"
                  type="select"
                  options={scores}
                ></FormField>
                <FormField
                  inputName="confidence"
                  type="select"
                  options={scores}
                ></FormField>
                <FormField
                  inputName="ease"
                  type="select"
                  options={scores}
                ></FormField>
              </Grid>

              <Grid templateColumns="repeat(3,1fr)" gap="15px" mb="20px">
                <FormField
                  inputName="costEstimateLow"
                  inputNameDisplay="Cost Estimate Low"
                  type="number"
                ></FormField>
                <FormField
                  inputName="costEstimateHigh"
                  inputNameDisplay="Cost Estimate High"
                  type="number"
                ></FormField>
                <FormField
                  inputName="status"
                  type="select"
                  options={["rejected", "no status", "approved"]}
                ></FormField>
              </Grid>

              <Box mb="40px">
                <FormField inputName="body" type="wysiwyg"></FormField>
              </Box>

              <Flex justifyContent="space-between">
                <Button size="lg" paddingX="40px" onClick={props.onClose}>
                  Cancel
                </Button>
                <Button
                  colorScheme="blue"
                  size="lg"
                  type="submit"
                  paddingX="60px"
                  isLoading={isSaving}
                >
                  {props.idea ? "Save" : "Submit"}
                </Button>
              </Flex>
              <Box marginTop="20px" textAlign="left">
                {idea.id && !isDeleting && (
                  <Link
                    color="red.500"
                    onClick={() => {
                      deleteIdea(idea, setIsDeleting, props.onClose);
                    }}
                  >
                    Delete Idea
                  </Link>
                )}

                {isDeleting && <Spinner color="red.600"></Spinner>}
              </Box>

              {idea.copiedFrom && (
                <Box color="#ccc" mt="10px" fontSize="14px">
                  Copied from {idea.copiedFrom}
                </Box>
              )}
            </Form>
          </Formik>
        </Box>
      </Box>
    </Box>
  );
}
