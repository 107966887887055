import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { User } from "./Auth";
import { DSButton } from "./ds/DSButton";
import { DSModal } from "./ds/DSModal";
import { fs } from "./firestore";
import { useSelectedOrg } from "./Org";
import { Org } from "./OrgInterface";

export function OrgSettings() {
  const org = useSelectedOrg();
  const history = useHistory();

  //   const onSubmit = () => {
  //     console.log("submitted");
  //   };

  function goBack() {
    history.push("/orgs/" + org?.id);
  }

  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    if (org && users?.length === 0)
      getMembers(org).then((users) => {
        setUsers(users);
      });
  }, [org]);

  return (
    <DSModal>
      <>
        {!org && <h2>This org doesnt exist</h2>}
        {org && (
          <div className="block w-full">
            <h1 className="text-3xl font-semibold mb-5">{org.title}</h1>
            <h3 className="text-lg font-semibold mb-2">Members</h3>
            {users.map((user) => (
              <div className="test" key={user.id}>
                {user.name}
              </div>
            ))}
            <div className="grid grid-cols-2 gap-10 items-center mt-10">
              <DSButton style="secondary" onClick={goBack}>
                Back
              </DSButton>
              {/* <DSButton onClick={onSubmit}>Save</DSButton> */}
            </div>
          </div>
        )}
      </>
    </DSModal>
  );
}

async function getMembers(org: Org) {
  const snap = await fs
    .collection("users")
    .where("orgs", "array-contains", org.id)
    .get();
  return snap.docs.map((doc) => {
    const userAny: any = doc.data();
    const user: User = userAny;
    return user;
  });
}
