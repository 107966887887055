import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Textarea,
} from "@chakra-ui/react";
import { Field } from "formik";
import React from "react";
import { RichTextEditor } from "./rich-text-editor";

export function FormField(props: {
  inputName: string;
  keepCase?: boolean;
  inputNameDisplay?: string;
  type: string;
  options?: string[];
}) {
  return (
    <Field name={props.inputName}>
      {({ field, form }: any) => (
        <FormControl
          isInvalid={
            form.errors[props.inputName] && form.touched[props.inputName]
          }
        >
          <FormLabel
            htmlFor={props.inputName}
            textTransform={props.keepCase ? undefined : "capitalize"}
          >
            {props.inputNameDisplay || props.inputName}
          </FormLabel>
          <FormFieldInput {...props} field={field} form={form}></FormFieldInput>
          <FormErrorMessage>{form.errors[props.inputName]}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
}

export function FormFieldInput(props: {
  type: string;
  field: any;
  form: any;
  inputName: string;
  options?: string[];
}) {
  switch (props.type) {
    case "textarea":
      return <Textarea {...props.field} />;
    case "number":
      return (
        <NumberInput
          defaultValue={props.field.value}
          onChange={(val) => {
            props.form.setFieldValue(props.field.name, val);
          }}
          step={500}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      );
    case "select":
      return (
        <Select {...props.field}>
          {props.options &&
            props.options.map((option) => {
              return (
                <option value={option} key={option}>
                  {option}
                </option>
              );
            })}
        </Select>
      );
    case "wysiwyg":
      return RichTextEditor(props);
    default:
      return <Input {...props.field} />;
  }
}
