import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAuthState } from "./Auth";
import firebase, { auth } from "./firestore";

import logoWhite from "./assets/logoWhite.svg";
import ideasPreview from "./assets/ideasPreview.png";
import { DSButton } from "./ds/DSButton";
import { DSModal } from "./ds/DSModal";
import { DSSpinner } from "./ds/DSSpinner";

export function Login() {
  const { isPending, user, fbUser } = useAuthState();
  const unknown = isPending;
  const loggedInPreUser = !isPending && fbUser && !user;
  const loggedOut = !isPending && !fbUser;

  const history = useHistory();
  useEffect(() => {
    if (user) {
      const state: any = history.location.state;
      const newLocation = state?.referrer?.pathname || "/";
      history.push(newLocation);
    }
  }, [user]);

  return (
    <>
      {(unknown || loggedInPreUser) && (
        <DSModal>
          <div className="flex justify-center items-center">
            <DSSpinner />
          </div>
        </DSModal>
      )}

      {loggedOut && (
        <div className="bg-black overflow-auto py-12 md:py-40">
          <div>
            <div className="flex justify-center px-4 mb-8">
              <img src={logoWhite} alt="" height="63px" />
            </div>
            <div className="md:flex md:flex-row-reverse">
              <div className="w4/5 md:w-2/5 py-12 hidden md:block">
                <img
                  src={ideasPreview}
                  alt=""
                  className="mx-auto md:max-w-none"
                />
              </div>
              <div className="text-white font-sans md:w-3/5 p-4 md:p-20">
                <div className="max-w-2xl float-right">
                  <h1 className="text-2xl md:text-5xl font-bold mb-4 md:mb-8 leading-tight">
                    100s of proven tactics to grow your startup or side hustle.
                  </h1>
                  <h2 className="text-lg md:text-2xl font-medium mb-4 md:mb-10 leading-normal">
                    A curated list of actionable experiments you can implement
                    today. Combined with a workflow to manage growth experiments
                    at scale.
                  </h2>

                  <img src={ideasPreview} alt="" className="md:hidden mb-6" />
                  <DSButton
                    onClick={() => loginWithGoogle()}
                    additionalClasses="w-full md:w-auto"
                    style="hero"
                  >
                    Get Started With Google
                  </DSButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function loginWithGoogle() {
  const provider = new firebase.auth.GoogleAuthProvider();
  auth
    .signInWithPopup(provider)
    .then(function (result) {
      console.log(result);
    })
    .catch(function (error) {
      console.log(error);

      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;
      // ...
    });
}
