import React from "react";

const topStyle = {
  border: "3px solid",
  borderTop: "3px solid transparent",
};
export function DSSpinner() {
  return (
    <div
      className="animate-spin loader ease-linear rounded-full border-gray-800 h-8 w-8"
      style={topStyle}
    ></div>
  );
}
