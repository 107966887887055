import React from "react";

export function DSModal(props: {
  children: string | JSX.Element | JSX.Element[];
}) {
  return (
    <div className="flex justify-center items-center h-screen">
      <div className="p-8 bg-white rounded-xl flex justify-center items-center w-96">
        <div className="block w-full">{props.children}</div>
      </div>
    </div>
  );
}
