import React, { useState, useEffect, createContext, useContext } from "react";
import firebase from "firebase";
import { auth, fs } from "./firestore";

export interface User {
  email: string;
  id: string;
  name: string;
  orgs: string[];
}

interface AuthState {
  status: "pending" | "success" | "error";
  error: firebase.auth.Error | null;
  fbUser: firebase.User | null;
  user: User | null;
}
const initialState: AuthState = {
  status: "pending",
  error: null,
  fbUser: null,
  user: null,
};

const AuthContext = createContext<AuthState>(initialState);
export function AuthProvider(props: any) {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    auth.onAuthStateChanged(
      (fbUser) => {
        if (fbUser) {
          setState({
            status: "success",
            error: null,
            fbUser: fbUser,
            user: null,
          });
        } else {
          setState({
            status: "success",
            error: null,
            fbUser: null,
            user: null,
          });
        }
      },
      (error) => {
        setState({
          status: "error",
          error,
          fbUser: null,
          user: null,
        });
      }
    );
  }, []);

  useEffect(() => {
    const userId = state?.fbUser?.uid;
    if (!userId) {
      setState({ ...state, user: null });
      return;
    }

    const ref = fs.doc("users/" + userId);
    const unsubscribe = ref.onSnapshot((snap) => {
      if (!snap.exists) {
        ref.set({
          id: userId,
          orgs: ["wYJW4T4nLrr37aJaglul"],
          name: state.fbUser?.displayName,
        });
        setState({ ...state, user: null });
        return;
      }
      const userAny: any = snap.data();
      const user: User = userAny;
      setState({ ...state, user });

      ref.set(
        {
          name: state.fbUser?.displayName,
          email: state.fbUser?.email,
        },
        { merge: true }
      );
    });
    return unsubscribe;
  }, [state.fbUser]);

  return (
    <AuthContext.Provider value={state}>{props.children}</AuthContext.Provider>
  );
}

export function useAuthState() {
  const state = useContext(AuthContext);
  return {
    ...state,
    isPending: state?.status === "pending",
    isError: state?.status === "error",
    isSuccess: state?.status === "success",
    isAuthenticated: state?.user && state?.status === "success",
  };
}
