import React from "react";
import "./Card.scss";
import { Idea } from "./indeaInterface";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import { fs } from "./firestore";
import { useSelectedOrg } from "./Org";

export function Card(props: { idea: Idea }) {
  const org = useSelectedOrg();
  const idea = props.idea;
  const iceClass = idea.status.split(" ").join("-") + " ice";

  function toggleClass() {
    const status =
      idea.status === "no status"
        ? "approved"
        : idea.status === "approved"
        ? "rejected"
        : "no status";
    fs.doc("ideas/" + idea.id).update({ status });
  }

  return (
    <div className="card">
      <Link to={"/orgs/" + org?.id + "/ideas/" + idea.id}></Link>
      <Box className="top" height="50px">
        <div className="emoji">{idea.emoji}</div>
        <div className={iceClass} onClick={toggleClass}>
          {idea.ice && idea.ice.toFixed(1)}
        </div>
      </Box>
      <div className="content">
        <div className="stage">{idea.stage}</div>
        <div className="title">{idea.title}</div>
        <div className="description">{idea.description}</div>
        <div className="cost-estimate">
          <div className="estimate low">${idea.costEstimateLow}</div>-
          <div className="estimate high">{idea.costEstimateHigh}</div>
        </div>
      </div>
    </div>
  );
}
