import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuthState } from "./Auth";
import { DSButton } from "./ds/DSButton";
import { DSSpinner } from "./ds/DSSpinner";
import { useOrgs } from "./useOrgs";

export function OrgSelector() {
  const { isPending, user } = useAuthState();
  const { orgs, loading } = useOrgs();

  const history = useHistory();
  if (orgs.length === 1) {
    history.push("orgs/" + orgs[0].id);
  }

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="p-8 bg-white rounded-xl flex justify-center items-center w-96">
        {(loading || isPending) && <DSSpinner />}
        {!(loading || isPending) && (
          <>
            {orgs.length === 0 && (
              <div className="text-center">You're not on any teams yet</div>
            )}
            {orgs.length > 0 && (
              <div className="text-center w-full">
                <h2 className="text-lg mb-8 mt-0 font-bold">
                  Select Organization
                </h2>
                <div className="grid gap-3">
                  {orgs?.map((org) => (
                    <DSButton key={org.id} additionalClasses="w-full">
                      <Link to={`/orgs/${org.id}`} className="text-white">
                        {org.title}
                      </Link>
                    </DSButton>
                  ))}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
