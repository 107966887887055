import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useAuthState } from "./Auth";
import { DSButton } from "./ds/DSButton";
import { DSInput } from "./ds/DSInput";
import { DSModal } from "./ds/DSModal";
import { DSSpinner } from "./ds/DSSpinner";
import { fs } from "./firestore";
import { Org } from "./OrgInterface";
import { makeSlug } from "./utils/make-slug";
import { newId } from "./utils/new-id";

type FormData = {
  firstName: string;
  lastName: string;
};

export function AddNewOrg() {
  const history = useHistory();
  const { user } = useAuthState();
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit } = useForm<FormData>();

  const onSubmit = handleSubmit(async (org: Org) => {
    setLoading(true);
    if (!org.id) org.id = newId();
    if (!org.slug) org.slug = makeSlug(org.title);

    setLoading(true);
    await fs.collection("orgs").doc(org.id).set(org);

    if (user) {
      const { orgs } = user;
      orgs.push(org.id);
      await fs.collection("users").doc(user.id).update({ orgs });
    }
    history.push("/orgs/" + org.id);
  });

  return (
    <DSModal>
      <>
        {loading && <DSSpinner></DSSpinner>}
        {!loading && (
          <div className="block w-full">
            <form onSubmit={onSubmit}>
              <DSInput
                name="title"
                label="Organization Name"
                placeholder="Apple"
                className="mb-4"
                innerref={register}
              ></DSInput>

              <div className="grid grid-cols-2 gap-10 items-center">
                <DSButton style="secondary" onClick={() => history.goBack()}>
                  Cancel
                </DSButton>
                <DSButton onClick={onSubmit}>Save</DSButton>
              </div>
            </form>
          </div>
        )}
      </>
    </DSModal>
  );
}
