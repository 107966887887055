import React, { useEffect, useState } from "react";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./rich-text-editor.css";

export function RichTextEditor(props: {
  type: string;
  field: any;
  form: any;
  inputName: string;
  options?: string[];
}) {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    const state = stateFromHTML(props.field.value);
    const initialState = EditorState.createWithContent(state);
    setEditorState(initialState);
  }, []);

  useEffect(() => {
    const value = stateToHTML(editorState.getCurrentContent());
    props.form.setFieldValue(props.field.name, value);
  }, [editorState]);

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={setEditorState}
      editorClassName="idea-single-body"
      spellCheck={true}
      toolbar={{
        options: ["inline", "blockType", "list", "textAlign", "link"],
        inline: {
          options: ["bold", "italic", "underline", "strikethrough"],
        },
        blockType: {
          options: ["Normal", "H1", "H2", "H3"],
        },
        list: { inDropdown: true },
        textAlign: { inDropdown: true },
        link: {
          defaultTargetOption: "_blank",
        },
      }}
    />
  );
}
