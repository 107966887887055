import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuthState } from "./Auth";
import { CardsList } from "./CardsList";
import { fs } from "./firestore";
import { Org } from "./OrgInterface";
import { OrgSelector } from "./OrgSelector";

export function OrgView() {
  const location = useLocation();
  if (location.pathname === "/") return <OrgSelector />;
  return <CardsList />;
}

export function useSelectedOrg() {
  const location = useLocation();
  const [org, setOrg] = useState<Org | null>(null);

  const { user } = useAuthState();
  let orgId =
    location?.pathname?.includes("orgs/") &&
    location?.pathname?.split("orgs/")?.pop();

  if (orgId) orgId = orgId.split("/")[0];

  useEffect(() => {
    fs.doc("orgs/" + orgId).onSnapshot((snap) => {
      if (!snap.exists) return setOrg(null);
      const orgAny: any = snap.data();
      const org: Org = orgAny;
      setOrg(org);
    });
  }, [orgId, user]);

  return org;
}
