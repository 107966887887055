import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { fs } from "./firestore";
import { Idea } from "./indeaInterface";
import {
  Box,
  Button,
  Grid,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from "@chakra-ui/react";
import { NewIdeaForm } from "./NewIdeaForm";
import { useOrgs } from "./useOrgs";
import { newId } from "./utils/new-id";
import { Org } from "./OrgInterface";
import { useSelectedOrg } from "./Org";
import "./IdeaSingle.scss";

export function IdeaSingle({ referrer = "/" }: { referrer: string }) {
  const { id } = useParams<{ id: string }>();
  const [idea, setIdea] = useState<Idea>();
  const [edit, setEdit] = useState(id === "add" ? true : false);
  const history = useHistory();

  useEffect(() => {
    if (id === "add") {
      setIdea(undefined);
      return;
    }

    const unsubscribe = fs.doc("ideas/" + id).onSnapshot((snapshot) => {
      if (!snapshot.exists) return;
      const idea: any = snapshot.data();
      setIdea(idea);
    });
    return unsubscribe;
  }, [id]);

  function onClose() {
    const rootPath = history.location.pathname.split("/ideas/")[0];
    history.push(rootPath);
  }

  const modalHeaderName = idea?.title || "Add New Idea";

  return (
    <Modal isOpen={true} onClose={() => onClose()} size="full">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <Grid gridTemplateRows="max-content 1fr" height="100%">
          <ModalHeader>
            <Box display="flex" alignItems="center">
              <Box>{modalHeaderName}</Box>
              {idea && (
                <Box marginLeft="20px">
                  <CopyTo idea={idea} onComplete={() => onClose()} />
                  {!edit && (
                    <Button marginLeft="10px" onClick={() => setEdit(true)}>
                      Edit
                    </Button>
                  )}
                  {edit && (
                    <Button marginLeft="10px" onClick={() => setEdit(false)}>
                      Cancel
                    </Button>
                  )}
                </Box>
              )}
            </Box>
          </ModalHeader>
          <Box overflow="scroll" height="100%">
            <Box margin="0 auto" maxW="900px" width="100%" padding="10px">
              {idea && !edit && (
                <Box paddingTop="30px">
                  <Heading marginBottom="10px">
                    {idea.emoji} {idea.title}
                  </Heading>
                  <Box fontSize="20px" color="#aaa">
                    {idea.description}
                  </Box>
                  <Box>
                    <Box
                      textTransform="capitalize"
                      color="#21c372"
                      fontWeight="bold"
                    >
                      {idea.stage}
                    </Box>
                  </Box>
                  <Box
                    paddingTop="20px"
                    className="idea-single-body"
                    dangerouslySetInnerHTML={{ __html: idea.body }}
                  ></Box>
                </Box>
              )}

              {edit && (
                <NewIdeaForm
                  onClose={() => {
                    if (idea) {
                      setEdit(false);
                    } else {
                      onClose();
                    }
                  }}
                  idea={idea}
                  key={idea?.id}
                ></NewIdeaForm>
              )}
            </Box>
          </Box>
        </Grid>
      </ModalContent>
    </Modal>
  );
}

export function CopyTo({
  idea,
  onComplete,
}: {
  idea: Idea;
  onComplete: Function;
}) {
  const { orgs } = useOrgs();
  const selectedOrg = useSelectedOrg();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  async function copyIdeaToOrg(org: Org, idea: Idea, onComplete: Function) {
    setLoading(true);
    idea.copiedFrom = idea.id;
    idea.id = newId();
    idea.orgId = org.id;
    await fs.doc("ideas/" + idea.id).set(idea);
    history.push("/orgs/" + org.id + "/ideas/" + idea.id);
    setLoading(false);
  }

  return (
    <>
      <Menu>
        <MenuButton as={Button}>Copy To</MenuButton>
        <MenuList>
          {orgs
            .filter((org) => org.id !== selectedOrg?.id)
            .map((org) => (
              <MenuItem
                key={org.id}
                onClick={() => {
                  copyIdeaToOrg(org, idea, onComplete);
                }}
              >
                {org.title}
              </MenuItem>
            ))}
        </MenuList>
      </Menu>
      {loading && (
        <Modal isOpen={true} onClose={() => {}} isCentered>
          <ModalOverlay />
          <ModalContent
            w="300px"
            padding="30px"
            backgroundColor="#fff"
            borderRadius="15px"
            minH="150px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Spinner />
          </ModalContent>
        </Modal>
      )}
    </>
  );
}
