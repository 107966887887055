import React from "react";
import "./App.scss";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./Auth";
import { AppRouting } from "./AppRouting";

function App() {
  return (
    <AuthProvider>
      <ChakraProvider>
        <Router>
          <div className="App">
            <AppRouting />
          </div>
        </Router>
      </ChakraProvider>
    </AuthProvider>
  );
}

export default App;
